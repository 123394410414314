<template>
  <div class="ui-title" :class="[`ui-title--${align}`]">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'UiTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    align: {
      type: String,
      required: false,
      default: 'left',
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  font-size: $font-size-lg;
  font-weight: $font-weight-title;

  &--center {
    text-align: center;
  }
}
</style>
