<template>
  <div class="radar">
    <div class="radar__message">
      <ui-title v-if="!radarUrl" :title="$t('radar.message.disabled')" />
    </div>
    <iframe v-if="radarUrl" frameborder="0" noborder class="radar__iframe" :src="radarUrl"></iframe>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UiTitle from '@/components/UI/Title.vue'

export default {
  name: 'Radar',
  components: { UiTitle },
  computed: {
    ...mapState({
      radarUrl: state => state.radar.appUrl,
    }),
  },
  methods: {
    ...mapActions({
      getAppUrl: 'radar/getAppUrl',
    }),
  },
  mounted() {
    this.getAppUrl()
  },
}
</script>

<style lang="scss" scoped>
.radar {
  &__message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 100%;
    color: $radar-message-disabled-color;
  }

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
